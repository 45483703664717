import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, FileText, Key, PieChart, Beaker, Linkedin, Twitter } from 'lucide-react';
import { Link } from 'react-router-dom';
import PhiAnimation from './PhiAnimation';

const NavItem = ({ item, scrolled }) => {
    const href = item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`;
    return (
        <Link to={href} className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`} onClick={() => window.scrollTo(0, 0)}>
            {item}
        </Link>
    );
};

const ServiceItem = ({ icon: Icon, title, description }) => (
    <div className="bg-white p-8 rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 flex flex-col items-center text-center h-full">
        <Icon className="w-12 h-12 mb-6 text-black" />
        <h3 className="text-2xl font-semibold mb-4">{title}</h3>
        <p className="text-gray-800 text-base">{description}</p>
    </div>
);

const MethodologyItem = ({ icon: Icon, title, description }) => (
    <div className="flex flex-col items-center text-center p-6 bg-gray-50 rounded-xl shadow-lg">
        <Icon className="w-16 h-16 mb-6 text-black" />
        <h3 className="text-xl font-semibold mb-4">{title}</h3>
        <p className="text-gray-800 text-base">{description}</p>
    </div>
);

const Home = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landingPadding, setLandingPadding] = useState('pt-40 pb-32 md:pt-56 md:pb-40');

    const navItems = ['Services', 'Research', 'About Exa'];

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 50);

            if (scrollPosition > 500) {
                setLandingPadding('pt-24 pb-20 md:pt-32 md:pb-24');
            } else if (scrollPosition > 200) {
                setLandingPadding('pt-32 pb-24 md:pt-40 md:pb-32');
            } else {
                setLandingPadding('pt-40 pb-32 md:pt-56 md:pb-40');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="font-sans bg-white text-black">
            <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${scrolled ? 'bg-black shadow-lg py-3' : 'bg-transparent py-4'}`}>
                <div className="container mx-auto px-6">
                    <div className="flex justify-between items-center">
                        <Link to="/" className={`text-2xl md:text-3xl font-bold transition-all duration-300 ${scrolled ? 'text-white' : 'text-white'}`} onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav className="hidden md:block">
                            <ul className="flex space-x-8">
                                {navItems.map((item) => (
                                    <li key={item}><NavItem item={item} scrolled={scrolled} /></li>
                                ))}
                            </ul>
                        </nav>
                        <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className={`hidden md:block px-8 py-3 rounded-full text-base font-bold transition-all duration-300 ${scrolled ? 'bg-white text-black hover:bg-gray-200' : 'bg-white text-black hover:bg-gray-200'}`}>
                            BOOK CALL
                        </a>
                        <button
                            className="md:hidden"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}
                        >
                            {mobileMenuOpen ? <X size={24} className={scrolled ? 'text-white' : 'text-white'} /> : <Menu size={24} className={scrolled ? 'text-white' : 'text-white'} />}
                        </button>
                    </div>
                </div>
            </header>

            <div
                className={`fixed inset-0 bg-black bg-opacity-75 z-40 transition-opacity duration-300 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setMobileMenuOpen(false)}
            >
                <div
                    className={`fixed right-0 top-0 bottom-0 w-3/4 bg-black shadow-2xl transform transition-transform duration-300 ease-in-out ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <nav className="p-6 pt-20">
                        <ul className="space-y-6">
                            {navItems.map((item) => (
                                <li key={item}><NavItem item={item} scrolled={true} /></li>
                            ))}
                            <li>
                                <a href="https://calendly.com/exaresearch" target="_blank" rel="noopener noreferrer" className="w-full bg-white text-black px-8 py-4 rounded-full text-lg font-bold hover:bg-gray-200 transition mt-8 block text-center">BOOK CALL</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <section className={`relative bg-black text-white ${landingPadding} rounded-b-[10rem] shadow-2xl transition-all duration-500`}>
                <div className="container mx-auto px-6">
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 mb-12 md:mb-0 w-full">
                            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-8 leading-tight text-center md:text-left">Developing Tokenized Economies with Data Driven Solutions</h1>
                            <p className="text-xl md:text-2xl mb-10 text-gray-300 text-center md:text-left">
                                We help businesses build and launch sustainable tokenized ventures by utilizing data-driven methodologies and proven strategies from the industry.
                            </p>
                            <div className="flex justify-center md:justify-start">
                                <Link to="/services" className="bg-white text-black px-10 py-5 rounded-full text-lg font-bold hover:bg-gray-200 transition inline-flex items-center group shadow-lg hover:shadow-xl" onClick={() => window.scrollTo(0, 0)}>
                                    Explore Our Services
                                    <ChevronRight className="ml-3 transition-transform duration-300 transform group-hover:translate-x-1" size={28} />
                                </Link>
                            </div>
                        </div>
                        <div className="md:w-1/2 mt-8 md:mt-0">
                            <div className="aspect-w-4 aspect-h-3 mx-auto max-w-lg">
                                <img src="/Worked for Logos copy/logo enhanced.png" alt="Network visualization" className="w-full h-full object-cover rounded-3xl shadow-2xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-32 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold mb-16 text-center">Building and Launching Tokenized Ventures with Expertise</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                        <ServiceItem
                            icon={PieChart}
                            title="Tokenomics Solutions"
                            description="We analyze market trends and design custom tokenomics strategies for sustainable ventures."
                        />
                        <ServiceItem
                            icon={Key}
                            title="Token Launch Strategy"
                            description="We provide guidance and support to successfully enter the tokenized market space."
                        />
                        <ServiceItem
                            icon={FileText}
                            title="Whitepaper & Pitch Deck"
                            description="We help create compelling narratives and investment material to engage and attract users to your tokenized ventures."
                        />
                        <ServiceItem
                            icon={Menu}
                            title="Exchange & Market Makers Support"
                            description="We leverage connections with centralized exchanges and market makers to ensure a smooth token launch and holder growth."
                        />
                    </div>
                </div>
            </section>

            <section className="py-32 bg-gray-100">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold mb-16 text-center">Methodology</h2>
                    <div className="flex justify-center">
                        <div className="w-full max-w-6xl bg-white p-10 rounded-3xl shadow-2xl">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                                <MethodologyItem
                                    icon={FileText}
                                    title="Analysis & Benchmarking"
                                    description="We examine your situation and conduct comparative analyses to deeply understand tokenomics in similar projects."
                                />
                                <MethodologyItem
                                    icon={Key}
                                    title="Token Strategy"
                                    description="We explore the rationale behind a project's token implementation and identify the key components needed to ensure its intended purpose and usefulness are met."
                                />
                                <MethodologyItem
                                    icon={PieChart}
                                    title="Token Economy"
                                    description="We carefully evaluate the stakeholders involved in the ecosystem and assess distribution, injection & sinks, liquidity, and vesting."
                                />
                                <MethodologyItem
                                    icon={Beaker}
                                    title="Token Simulation"
                                    description="In our simulation process, we utilize Python and specialized simulation software to create accurate and dynamic simulations of your token ecosystem."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-32 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold mb-8 text-center">Unlocking Tokenomics: Insights, Analysis, and Solutions</h2>
                    <p className="text-xl text-center mb-16 max-w-4xl mx-auto">
                        Our analysis in tokenomics accelerate businesses to navigate the complexities of the blockchain industry. With data-driven insights and proven strategies, we provide bespoke solutions that drive sustainable growth and success.
                    </p>
                    <PhiAnimation />
                </div>
            </section>

            <footer className="bg-black text-white py-20">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col items-center mb-16">
                        <Link to="/" className="text-3xl font-bold mb-8" onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav>
                            <ul className="flex flex-wrap justify-center space-x-4 md:space-x-10">
                                <li className="w-full sm:w-auto text-center mb-4">
                                    <a href="mailto:research@exagroup.xyz" className="text-lg hover:text-gray-200 transition flex items-center justify-center">
                                        <FileText className="mr-3" size={24} />Contact
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-4">
                                    <a href="https://www.linkedin.com/company/exagroupxyz/about/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-lg hover:text-gray-200 transition flex items-center justify-center">
                                        <Linkedin className="mr-3" size={24} />LinkedIn
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-4">
                                    <a href="https://x.com/exagroupxyz"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-lg hover:text-gray-200 transition flex items-center justify-center">
                                        <Twitter className="mr-3" size={24} />Twitter
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-4">
                                    <a href="https://blog.exagroup.xyz/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-lg hover:text-gray-200 transition flex items-center justify-center">
                                        <FileText className="mr-3" size={24} />Research
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="text-center">
                        <p>43 Portsea Hall, Portsea Place, W2 2BW, London</p>
                    </div>
                    <hr className="border-gray-700 my-10" />
                    <p className="text-center text-gray-400 text-sm">&copy; 2024 EXA RESEARCH LIMITED. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Home;