import React from 'react';

const PhiAnimation = () => {
    return (
        <div className="w-full h-[40vh] md:h-[45vh] bg-black flex flex-col items-center justify-center overflow-hidden rounded-2xl md:rounded-3xl shadow-xl">
            <div className="eye">
                <div className="eye">
                    <div className="eye">
                        <div className="eye">
                            <div className="eye">
                                <div className="eye">
                                    <div className="eye">
                                        <div className="eye">
                                            <div className="eye">
                                                <div className="eye">
                                                    <div className="eye">
                                                        <div className="eye">
                                                            <div className="eye">
                                                                <div className="eye">
                                                                    <div className="eye">
                                                                        <div className="eye">
                                                                            <div className="eye">
                                                                                <div className="eye">
                                                                                    <div className="eye">
                                                                                        <div className="eye">
                                                                                            <div className="eye">
                                                                                                <div className="eye">
                                                                                                    <div className="eye">
                                                                                                        <div className="eye">
                                                                                                            <div className="eye">
                                                                                                                <div className="eye">
                                                                                                                    <div className="eye">
                                                                                                                        <div className="eye">
                                                                                                                            <div className="eye"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
        .eye {
          box-sizing: border-box;
          transform: rotate(45deg);
          background-color: rgba(59, 130, 246, 0.05);
          width: 35vmin;
          aspect-ratio: 1;
          border: 1px solid rgba(59, 130, 246, 0.15);
          border-radius: 100% 0;
          position: relative;
          overflow: clip;
          animation: 8s spin infinite ease-in-out alternate;
          box-shadow: 0 0 30px rgba(59, 130, 246, 0.1);
        }
        
        .eye > .eye {
          top: 50%;
          left: 50%;
          width: 100%;
          height: 88%;
          position: absolute;
          transform: translateX(-50%) translateY(-50%) rotate(calc(var(--off, 5) * 1deg));
          backdrop-filter: blur(5px);
        }
        
        @property --off {
          syntax: "<number>";
          inherits: true;
          initial-value: 3;
        }
        
        @keyframes spin {
          0% { 
            --off: -42;
            border-color: rgba(59, 130, 246, 0.1);
          }
          50% {
            border-color: rgba(59, 130, 246, 0.2);
          }
          100% { 
            --off: 42;
            border-color: rgba(59, 130, 246, 0.1);
          }
        }

        .eye:hover {
          animation-duration: 4s;
          background-color: rgba(59, 130, 246, 0.08);
        }
      `}</style>
        </div>
    );
};

export default PhiAnimation;