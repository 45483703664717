import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import AboutUs from './components/AboutUs';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-exa" element={<AboutUs />} />
        <Route
          path="/research"
          element={<Research />}
        />
      </Routes>
    </Router>
  );
}

// New component to handle the research route
function Research() {
  React.useEffect(() => {
    window.location.href = 'https://blog.exagroup.xyz/';
  }, []);

  return null;
}

export default App;